<template>
  <v-container align-center justify-center fill-height pa-4>
    <v-layout
      :style="isMobile ? 'padding:20px' : ''"
      class="background"
      text-
      align-center
      justify-center
      column
    >
      <v-card
        :style="isMobile ? 'margin:10px; padding:20px' : ''"
        :loading="loading"
        class="card"
        color="#fff"
      >
        <v-card-title style="display: flex; justify-content: center">
          <img
            style="border-radius: 0px 0px 0px 0px !important; max-width: 170px"
            class="mt-5 mb-5"
            :src="logo"
          />
          <!-- <span
            style="text-align: center"
            class="display-1 font-weight-bold mb-5"
          >
            <span class="font-weight-light">Sistema</span>
            Administrativo
          </span> -->
        </v-card-title>
        <v-card-subtitle>{{ $tc("login.insert_message") }}</v-card-subtitle>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @keyup.enter.native="onNext()"
          >
            <v-text-field
              v-model="email"
              :rules="[rules.required, rules.email]"
              ref="mailAddress"
              type="email"
              name="input-10-1"
              :label="$tc('login.email')"
              hint="correo@servidor.tldn"
              rounded
              filled
              clearable
            ></v-text-field>

            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'fa-eye' : 'fa-eye-slash'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              :label="$tc('login.password')"
              :hint="$tc('login.min_password')"
              rounded
              filled
              clearable
              @click:append="show1 = !show1"
            ></v-text-field>

            <v-btn
              @click="onNext()"
              color="buttons"
              :style="isMobile ? 'margin-bottom: 10px' : ''"
              rounded
              block
              large
              :elevation="0"
              :minHeight="56"
              :loading="loading"
              :disabled="loading"
              >{{ $tc("login.login_btn") }}</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
      <v-snackbar
        content-class="centered"
        absolute
        top
        :timeout="3000"
        v-model="snackbar"
        >{{ snackbarText }}</v-snackbar
      >
      <p
        v-if="!isMobile"
        class="white--text disclaimer-text mt-4"
        style="z-index: 9999"
      >
        <br />
        <i class="fas fa-code"></i> por:
        <b>
          <a href="http://beanar.io" class="white--text" target="_blank"
            >Beanario Software</a
          >
          an Indihos Company
        </b>
        <br />
        <span>&copy; {{ new Date().getFullYear() }}</span>
        <br />
      </p>
    </v-layout>

    <v-dialog
      persistent
      max-width="400px"
      v-if="tokenDialog"
      transition="dialog-bottom-transition"
      v-model="tokenDialog"
    >
      <token-input
        ref="tokenComponent"
        @cancel="tokenDialog = false"
        @insertedToken="onNext"
      />
    </v-dialog>

    <!-- <div class="area">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div> -->
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import mobile from "is-mobile";
import { mapState } from "vuex";

import tokenInput from "../components/tokenInput";
import JSEncrypt from "nodejs-jsencrypt";

export default {
  name: "login",
  components: {
    tokenInput,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      snackbar: false,
      snackbarText: null,
      show1: false,
      tokenDialog: false,
      loading: false,
      logo: require("@/assets/wink-logo.svg"),
      email: "",
      password: "",
      valid: true,
      rules: {
        required: (value) => !!value || this.$tc("rules.required"),
        min: (v) => (v && v.length >= 3) || this.$tc("login.min_password"),
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || this.$tc("login.invalid_email");
        },
      },
    };
  },
  computed: mapState(["userIsValid"]),
  watch: {
    userIsValid(newValue) {
      if (newValue === false) {
        this.loading = false;
      }
    },
  },
  methods: {
    isMobile() {
      return mobile();
    },

    signWithToken(token) {
      fb.auth()
        .setPersistence(fb.auth.Auth.Persistence.SESSION)
        .then(() => {
          fb.auth()
            .signInWithCustomToken(token)
            .then(() => {})
            .catch(() => {});
        });
    },

    RSAencrypt(d) {
      let jse = new JSEncrypt();
      let publicKey = process.env.VUE_APP_PUBLIC_KEY;

      jse.setPublicKey(publicKey);
      return JSON.stringify(d)
        .match(/.{1,100}/g)
        .map((part) => jse.encrypt(part));
    },

    async onNext(token) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          email: this.email,
          password: this.password,
        };

        if (token) {
          data.token = token;
        }

        const dataEncrypt = await this.RSAencrypt(data);

        var httpLogin = fb.functions().httpsCallable("httpLogin2");
        httpLogin({ request: dataEncrypt })
          .then((result) => {
            this.signWithToken(result.data.data.customToken);
          })
          .catch((err) => {
            console.log(err.code);
            switch (err.code) {
              case "functions/not-found":
                this.snackbarText = this.$tc("login.wrong_credentials");
                this.snackbar = true;
                break;

              case "functions/permission-denied":
                this.tokenDialog = true;
                break;

              case "functions/invalid-argument":
                this.snackbarText = this.$tc("login.wrong_credentials");
                this.snackbar = true;
                break;

              case "functions/unavailable":
                this.snackbarText = this.$tc("login.user_disabled");
                this.snackbar = true;
                break;

              case "functions/failed-precondition":
                this.$refs.tokenComponent.tokenError();
                break;

              default:
                this.snackbarText = this.$tc("error.unknown");
                this.snackbar = true;
                break;
            }
            this.loading = false;
          });
      } else {
        this.snackbarText = this.$tc("login.fill_fields");
        this.snackbar = true;
      }
    },
  },
};
</script>

<style scoped>
.background {
  background-color: #008bbf;
  height: 100vh;
  position: absolute;
  width: 100vw;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 10; */
  background-repeat: repeat;
}

.area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* z-index: 100; */
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #fdc21054;
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.card {
  /* z-index: 9999 !important; */
  width: 450px;
  /* z-index: 999;
  position: absolute; */
}
</style>

